<template>
  <div>
    <h5 class="fw-bold text-primary">Comments</h5>
    <!-- v-if="c.jsdPublic" -->
    <div v-for="c in comments" :key="c.id" class="mb-3">
      <div class="card border-0 shadow-none bg-light">
        <div class="card-body">
          <div v-html="c.renderedBody"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-auto ms-auto">
          <p>
            <small
              >{{ c.author.displayName === 'Swandoola AutoSupport' ? 'You' : c.author.displayName }} / Comment added:
              {{ c.created | formatDateTime }}</small
            >
          </p>
        </div>
      </div>
    </div>

    <div v-if="comments.length == 0">
      <div class="card border-0 shadow-none bg-light">
        <div class="card-body text-center">
          <div><i class="far fa-exclamation fa-2x text-primary"></i></div>
          <div><h6 class="fw-bold text-primary">No comments</h6></div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col my-auto">
        <textarea
          class="form-control"
          rows="2"
          placeholder="Add comment..."
          v-model="newComment"
        ></textarea>
      </div>
      <div class="col-auto ms-auto my-auto">
        <button class="btn btn-primary" @click="addComment">Add</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      comments: [],
      newComment: "",
      structComment: {
        body: {
          type: "doc",
          version: 1,
          content: [
            {
              type: "paragraph",
              content: [
                {
                  "text": "",
                  "type": "text"
                }
              ]
            }
          ]
        }
      },
    };
  },
  methods: {
    async fetchComments() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL +
          "/support-requests/" +
          this.id +
          "/comments"
      );
      this.comments = data.comments;
    },
    async addComment() {
      this.structComment.body.content[0].content[0].text = this.newComment;
      const { data } = await this.$axios.put(
        process.env.VUE_APP_API_URL +
          "/support-requests/" + this.id,
          {
            type: 'comment',
            data: this.structComment
          }
      );

      this.fetchComments();
    },
  },
  created() {
    this.fetchComments();
  },
  filters: {
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
  },
};
</script>

<style>
</style>