<template>
  <div class="container">
    <div class="row mb-2">
      <div class="col-auto ms-auto">
        <router-link
          to="/support-requests"
          class="btn btn-sm btn-outline-primary"
          ><i class="far fa-arrow-left me-2"></i>All tickets</router-link
        >
      </div>
    </div>
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div v-if="supportRequest">
          <div class="row mb-4">
            <div class="col my-auto">
              <h3 class="text-primary fw-bold mb-0">
                {{ supportRequest.fields.summary }}
              </h3>
            </div>
          </div>

          <div class="card border-0 shadow-none bg-light mb-4">
            <div
              v-if="supportRequest.renderedFields.description"
              class="card-body"
              v-html="supportRequest.renderedFields.description"
            ></div>

            <div v-else class="card-body">No description provided...</div>
          </div>

          <!--  -->

          <div class="row">
            <div class="col my-auto">
              <p class="mb-0">
                <small>
                  Ticket opened:
                  {{ supportRequest.fields.created | formatDateTime }}
                </small>
              </p>
            </div>
            <div class="col-auto ms-auto my-auto">
              <h5 class="text-secondary mb-0">
              Status: {{ supportRequest.fields.status.name  }}
              </h5>
              <button class="btn btn-sm btn-success" @click="completeSupportRequest" v-if="supportRequest.fields.status.id != 5">
                <i class="far fa-check me-2"></i>Mark ticket resolved
              </button>
            </div>
          </div>

          <!-- Comments -->
          <hr class="my-4" />
          <comments :id="supportRequest.id"></comments>
        </div>
        <busy v-else s></busy>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "@/components/Busy.vue";
import Comments from "./view-partials/Comments.vue";

export default {
  data() {
    return {
      supportRequest: null,
    };
  },
  methods: {
    async fetchSupportRequest() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL +
          "/support-requests/" +
          this.$route.params.id
      );

      this.supportRequest = data;
    },
    async completeSupportRequest() {
      const { data } = await this.$axios.put(
        process.env.VUE_APP_API_URL +
          "/support-requests/" +
          this.$route.params.id,
          {
            type: 'complete'
          }
      );

      this.fetchSupportRequest();
    },
  },
  mounted() {
    this.fetchSupportRequest();
  },
  filters: {
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
  },
  components: {
    Busy,
    Comments,
  },
};
</script>

<style>
</style>